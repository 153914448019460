import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

import {useClub} from './clubs';

const TournamentsLobbyContext = React.createContext<
  SubscriptionState<Gazebo.Lobby.AbstractTournament>
>({type: 'started'});

export const TournamentsLobbyProvider: React.FunctionComponent<{
  clubOrDisplayId: string | undefined;
}> = ({children, clubOrDisplayId}) => {
  const {subscribeToTournaments} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const club = useClub(clubOrDisplayId)?.data;
  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTournament>,
    ) => {
      const areaId = club?.leagueId ?? club?.clubId ?? 'global';
      return subscribeToTournaments(subscriber, [areaId]);
    },
    [club, subscribeToTournaments],
  );
  const subscription = useSubscription(subscribe);

  return (
    <TournamentsLobbyContext.Provider value={subscription}>
      {children}
    </TournamentsLobbyContext.Provider>
  );
};

export const useTournamentsLobby = () => {
  return React.useContext(TournamentsLobbyContext);
};
