import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

import {useClub} from './clubs';

const ClubLobbyContext = React.createContext<
  SubscriptionState<Gazebo.Lobby.PokerTable>
>({type: 'started'});

export const ClubLobbyProvider: React.SFC<{
  clubOrDisplayId: string | undefined;
}> = ({children, clubOrDisplayId}) => {
  const {subscribeToManagerTables} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  const club = useClub(clubOrDisplayId)?.data;
  const subscribe = React.useMemo(
    () =>
      club == null
        ? undefined
        : (
            subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTable>,
          ) => {
            const areaId = club.leagueId ?? club.clubId;
            return subscribeToManagerTables(subscriber, [areaId]);
          },
    [club, subscribeToManagerTables],
  );
  const subscription = useSubscription(subscribe);

  return (
    <ClubLobbyContext.Provider value={subscription}>
      {children}
    </ClubLobbyContext.Provider>
  );
};

export const useClubLobby = () => {
  return React.useContext(ClubLobbyContext);
};
